

html {
  overflow-y: hidden;
  font-size: 16px;
}

h3 {
  font-size: 1rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.25rem;
  padding-left: 0px;
  line-height: 1.2;
}
h2::before {
  content: none !important;
}

main {
  //padding-top: 100px;
  padding-bottom: 0px;
}

.big-modal{
  width:950px !important
}

.joyride-step__body {
  text-align: left;
  padding: 10px 8px;
  font-size: 13px;
}

joyride-step {
  background-color: #23233C;
  .joyride-step__container {
    background-color: #23233C;
    color: white;
  }
}

.joyride-button {
  background-color: #FF2640;
}

#joyride-step-step3 {
  .joyride-step__container{
    .joyride-step__body {
      white-space: pre-line;
    }
  }
}

#joyride-step-step7 {
  .joyride-step__container{
    .joyride-step__title {
      font-size: 17px;
      color: white !important;
    }
  }
}

.joyride-step__container {
  min-width: 216px;
}

.joyride-step__body > p {
  margin-bottom: 5px;
}

joyride-arrow .joyride-arrow__top {
  border-bottom: 11px solid #23233C;
}

joyride-arrow .joyride-arrow__left {
  border-right: 11px solid #23233C;
}

.joyride-step__header {
  color: white;
}

.joyride-step__close > svg > line {
  stroke: white;
}

.joyride-step__footer {
  .joyride-step__counter {
    visibility: hidden;
  }
}

.modal.show .modal-dialog {
  transform: none;
  margin-top: 6%;
}

.siemens-hide {
  color: #FF2640;
}
